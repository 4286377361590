<template>
  <v-form ref="form">
    <select-praca class="mr-2" :rules="obrigatorio"></select-praca>
    <v-menu
      class="pr-2"
      ref="retornoDateInicio"
      lazy
      :close-on-content-click="false"
      v-model="dataRetornoInicio"
      transition="scale-transition"
      offset-y
      full-width
      :nudge-bottom="-22"
      max-width="290px"
      :return-value.sync="dataRetornoEntradaInicio"
    >
      <v-text-field
        slot="activator"
        label="Data início retorno"
        v-model="dataRetornoEntradaInicio"
        append-icon="event"
        readonly
        :rules="obrigatorio"
      ></v-text-field>
      <v-date-picker v-model="dataRetornoEntradaInicio" no-title scrollable>
        <v-spacer></v-spacer>
        <v-btn flat color="primary" @click="dataRetornoInicio = false">Cancel</v-btn>
        <v-btn flat color="primary" @click="$refs.retornoDateInicio.save(dataRetornoEntradaInicio)">OK</v-btn>
      </v-date-picker>
    </v-menu>
    <v-menu
      class="pr-2"
      ref="retornoDateFim"
      lazy
      :close-on-content-click="false"
      v-model="dataRetornoFinal"
      transition="scale-transition"
      offset-y
      full-width
      :nudge-bottom="-22"
      max-width="290px"
      :return-value.sync="dataRetornoEntradaFinal"
    >
      <v-text-field
        slot="activator"
        label="Data fim retorno"
        v-model="dataRetornoEntradaFinal"
        append-icon="event"
        readonly
        :rules="obrigatorio"
      ></v-text-field>
      <v-date-picker v-model="dataRetornoEntradaFinal" no-title scrollable>
        <v-spacer></v-spacer>
        <v-btn flat color="primary" @click="dataRetornoFinal = false">Cancel</v-btn>
        <v-btn flat color="primary" @click="$refs.retornoDateFim.save(dataRetornoEntradaFinal)">OK</v-btn>
      </v-date-picker>
    </v-menu>
    <div class="form-btn">
      <v-btn outline @click="gerarRelatorio" color="primary">Gerar relatório</v-btn>
      <v-btn @click="voltar" outline>Voltar</v-btn>
    </div>
    <v-snackbar :color="'pink accent-1'" v-model="snackbar" :top="true">
      {{ text }}
    </v-snackbar>
  </v-form>
</template>
<script>
import { serverBus } from "../../../main"
import { RelatoriosSaida } from "../../../servicos/relatoriosSaida"
import SelectPraca from "../../praca/selectPracaPorFuncionario.vue"
const servicoRelatoriosSaida = new RelatoriosSaida()
export default {
  components: {
    SelectPraca
  },
  data() {
    return {
      snackbar: false,
      text: "Gerando arquivo para impressão. Aguarde!",
      idPraca: null,
      dataRetornoInicio: false,
      dataRetornoEntradaInicio: null,
      dataRetornoFinal: false,
      dataRetornoEntradaFinal: null,
      obrigatorio: [v => !!v || "Campo obrigatório"]
    }
  },
  created() {
    serverBus.$on("pracaSelected", value => {
      this.idPraca = value
    })
  },
  methods: {
    gerarRelatorio() {
      if (this.$refs.form.validate() && this.idPraca !== null) {
        this.text = "Gerando arquivo para impressão. Aguarde!"
        this.snackbar = true

        servicoRelatoriosSaida.imprimirCobrancaPorPraca(this.dataRetornoEntradaInicio, this.dataRetornoEntradaFinal, this.idPraca).then(
          response => {
            var blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
            var link = document.createElement("a")
            link.href = window.URL.createObjectURL(blob)
            var fileName = `${this.dataRetornoEntradaInicio}_${this.dataRetornoEntradaFinal}_cobrancaPorPraca.xlsx`
            link.download = fileName
            link.click()
            this.snackbar = false
          },
          err => {
            this.snackbar = true
            this.text = err.response.data[0].detail
          }
        )
      } else {
          alert("Selecione uma praça e preencha o período a ser consultado.")
      }
    },
    voltar() {
      this.$router.push({ name: "Relatorios" })
    }
  }
}
</script>
